import React from "react"
import { graphql } from "gatsby"
export default function MyFiles({ data }) {
  console.log(data)
  return (
      <div>
        <h1>My Site's Files</h1>
        <table>
          <thead>
            <tr>
              <th>directory</th>
              <th>name</th>
              <th>prettySize</th>
              <th>extension</th>
            </tr>
          </thead>
          <tbody>
            {data.allFile.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>{node.dir}</td>
                <td>{node.name}</td>
                <td>{node.prettySize}</td>
                <td>{node.ext}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  )
}
export const query = graphql`
  query {
    allFile {
        edges {
          node {
            id
            size
            birthtime
            name
            prettySize
            modifiedTime
            root
            dir
            ext
          }
        }
      }
  }
`